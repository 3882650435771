import { ArticlesBox as ArticlesBoxBase } from '@features/articles-box';
import styled from 'styled-components';

const ArticlesBox = styled(ArticlesBoxBase)`
  margin: 100px 0;
`;

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.white};
`;

export { ArticlesBox, Wrapper };
