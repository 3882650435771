import { PAGE_TYPE } from '@constants/cms';
import { IMAGE_LOCATION } from '@constants/pages';
import { LOCATION } from '@constants/types';
import { AdvantagePanel } from '@features/dynamic-landing-page/advantage-panel';
import { ContentPanel } from '@features/dynamic-landing-page/content-panel';
import { HeroLandingView } from '@features/page-blocks/hero-landing-view';
import { TYPE_KNOWLEDGE_SERIES_ARTICLE } from '@infra/cms/types';
import { useTranslation } from '@infra/i18n';
import { ROUTES } from '@infra/routes';
import { findContentGuideHeadings } from '@utils/find-content-guide-headings';
import PropTypes from 'prop-types';
import React from 'react';

import { ArticlesBox, Wrapper } from './styled-components';

const KnowledgeSeriesDetailsPage = props => {
  const { pageContent, pageData, location } = props;
  const { t } = useTranslation();

  const contentGuide = findContentGuideHeadings(pageContent);

  const heroProps = {
    image: pageData.cardImage,
    imageAltTag: pageData.cardImageAltTag,
    header: pageData.articleTitle,
    emphasizedText: '',
    showQuoteForm: true,
    imageLocation: IMAGE_LOCATION.LEFT,
    pageType: PAGE_TYPE.ORIGIN,
  };

  return (
    <Wrapper>
      <HeroLandingView {...heroProps} />

      <AdvantagePanel />
      <ContentPanel
        content={pageContent}
        title={pageData.articleTitle}
        categoryUrl={ROUTES.KNOWLEDGE_SERIES}
        categoryTitle={t('knowledgeSeries.knowledgeSeries')}
        contentGuide={contentGuide}
        location={location}
        readingTime={pageData.readingTime}
        updatedAt={pageData.publishedDate}
      />

      <ArticlesBox title="knowledgeSeries.youMayAlsoLike" />
    </Wrapper>
  );
};

KnowledgeSeriesDetailsPage.propTypes = {
  pageContent: PropTypes.string.isRequired,
  pageData: TYPE_KNOWLEDGE_SERIES_ARTICLE.isRequired,
  location: LOCATION.isRequired,
};

export { KnowledgeSeriesDetailsPage };
