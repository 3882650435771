import { KnowledgeSeriesDetailsPage } from '@features/knowledge-series-page';
import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { TYPE_KNOWLEDGE_SERIES_ARTICLE } from '@infra/cms/types';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';

const Template = props => {
  const {
    location,
    pageContext: { locale },
  } = props;

  const {
    fields: { readingTime },
    html,
  } = props?.data?.content; // eslint-disable-line react/destructuring-assignment
  const pageData = get(props, 'data.pageData');

  const {
    metaTitle: title,
    metaDescription: description,
    metaIndex: index,
    metaFollow: follow,
  } = pageData;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        title={title}
        description={description}
        isNoIndex={index === 'noindex'}
        isNoFollow={follow === 'nofollow'}
        lang={locale}
      />
      <KnowledgeSeriesDetailsPage
        pageData={{ ...pageData, readingTime }}
        pageContent={html}
        location={location}
      />
    </LayoutDefault>
  );
};

const query = graphql`
  query($id: String!) {
    pageData: strapiKnowledgeseriesarticle(id: { eq: $id }) {
      id
      articleTitle
      articleURL
      publishedDate(formatString: "MMM D, YYYY")
      metaTitle
      metaDescription
      metaIndex
      metaFollow
      cardImage {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
      cardImageAltTag
    }

    content: markdownRemark(parent: { parent: { id: { eq: $id } } }) {
      html
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;

Template.propTypes = {
  ...gatsbyPageProps,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    cms: TYPE_KNOWLEDGE_SERIES_ARTICLE,
    md: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
};

export default Template;
export { query, Template };
